import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Table,
  Input,
  Popover,
  Button,
  Switch,
  Tooltip,
  Select,
  Form,
  DatePicker,
  Divider,
  Space,
  Radio,
  Checkbox,
  Modal
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import KebabIcon from './icons/Kebab';
import NextIcon from './icons/Next';
import { OsdPopUp } from './OsdPopUp';
import { useSelector, useDispatch } from 'react-redux';
import { isItemsLoading, selectUser, userInputError, userInputLoading } from '../selectors/itemSelector';
import {
  selectOsdFilters,
  selectedPaperLotNumberFilters,
  selectedRevisedPaperLotNumberFilters,
  selectedSizeFilters,
  selectedPackFilters,
  selectedCatManEventFilters,
  selectedCatManLotFilters
} from '../selectors/hierarchySelector';
import 'antd/dist/antd.css';
import { saveUserInputs } from '../actions';
import moment from 'moment';
import { numberWithCommas, getSearchWords } from 'managers/components/TableLogic';
import _ from 'lodash';
import { getUserPermission, formatSelectedKey, getTheLogicValue } from '../utils/Util';
import { _digitalColumns } from './OptionalColumnsEditor';

let loggedInUser;
const EditableContext = React.createContext(null);
const { Option } = Select;
const packSizeFlagOptions = ['', 'Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'N1', 'N2', 'N3', 'N4'];
const brandDominatorFlagOptions = ['', 'Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'N1', 'N2', 'N3', 'N4'];
const futureBrandStrategyOptions = ['', 'National', 'Sysco'];
const revisedPgmLotMaxLength = 10;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleUserInputs, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (dataIndex === 'verified' || dataIndex === 'validated') {
        if (values.verified !== undefined && record.verified !== values.verified) {
          const verifiedBy = values.verified == null || values.verified == '' ? null : loggedInUser.email;
          values.verifiedBy = verifiedBy;
        }
        if (values.validated !== undefined && record.validated !== values.validated) {
          let validatedBy = values.validated === null || values.validated === '' ? null : loggedInUser.email;
          values.validatedBy = validatedBy;
        }
      }
      handleUserInputs({ ...record, ...values });
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.error('Save failed:', errInfo);
    }
  };

  const onChangeLotNum = async () => {
    try {
      let values = await form.validateFields();
      if (Object.keys(values).includes('revisedPgmLot')) {
        if (
          values.revisedPgmLot &&
          !(_.startsWith(values.revisedPgmLot, 'R-') || _.startsWith(values.revisedPgmLot, 'R'))
        ) {
          values.revisedPgmLot = 'R-' + values.revisedPgmLot;
          form.setFieldsValue({
            [dataIndex]: values.revisedPgmLot
          });
        } else if (values.revisedPgmLot && values.revisedPgmLot.trim().length == 0) {
          form.setFieldsValue({
            [dataIndex]: null
          });
        }
        // check string length
        if (values.revisedPgmLot.length > revisedPgmLotMaxLength) {
          values.revisedPgmLot = values.revisedPgmLot.substring(0, revisedPgmLotMaxLength);
          form.setFieldsValue({
            [dataIndex]: values.revisedPgmLot
          });
        }

        handleUserInputs({ ...record, ...values });
      }
    } catch (errInfo) {
      console.log('Lot number changing failed:', errInfo);
    }
  };

  const onChangeNumber = async () => {
    try {
      let values = await form.validateFields();
      const fields = ['preferredTotalCaseCount', 'preferredPack']; // add dataIndex to validate
      fields.forEach(field => {
        if (Object.keys(values).includes(field)) {
          if (values[field]) {
            values[field] = values[field].replace(/(\D)|(^0+)/gm, '');
            form.setFieldsValue({
              [dataIndex]: values[field]
            });
          } else if (values[field] && values[field].trim().length == 0) {
            form.setFieldsValue({
              [dataIndex]: null
            });
          }
        }
      });
      handleUserInputs({ ...record, ...values });
    } catch (errInfo) {
      console.log('Number changing failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing && title == 'Brand Dominator Flag') {
      childNode = (
        <Form.Item name={dataIndex} className="editable-cell-value-wrap-form">
          <Select className="select-before" onChange={save} ref={inputRef}>
            {brandDominatorFlagOptions.map(option => (
              <Option key={option} value={option || null}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (editing && title == 'Pack Size Flag') {
      childNode = (
        <Form.Item name={dataIndex} className="editable-cell-value-wrap-form">
          <Select className="select-before" onChange={save} ref={inputRef}>
            {packSizeFlagOptions.map(option => (
              <Option key={option} value={option || null}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (
      editing &&
      (title === 'Validated' ||
        title === 'Verified' ||
        title === 'Source' ||
        title === 'Under Contract' ||
        title === 'PQA Spec Item')
    ) {
      childNode = (
        <Form.Item name={dataIndex} className="editable-cell-value-wrap-form">
          <Select className="select-before" onChange={save} ref={inputRef}>
            <Option />
            <Option value="Y"> Y </Option>
            <Option value="N"> N </Option>
          </Select>
        </Form.Item>
      );
    } else if (editing && title == 'Future Brand Strategy') {
      childNode = (
        <Form.Item name={dataIndex} className="editable-cell-value-wrap-form">
          <Select className="select-before" onChange={save} ref={inputRef}>
            {futureBrandStrategyOptions.map(option => (
              <Option key={option} value={option || null}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    } else if (editing && title == 'Preferred Total Case Count') {
      let rules = [
        {
          required: false,
          message: `${title} is required.`
        }
      ];
      childNode = (
        <Form.Item className="editable-cell-value-wrap-form" name={dataIndex} rules={rules}>
          <Input ref={inputRef} onBlur={save} onChange={onChangeNumber} />
        </Form.Item>
      );
    } else if (editing && title === 'Notes To Sourcing') {
      childNode = (
        <Form.Item className="editable-cell-value-wrap-form" name={dataIndex}>
          <Input maxLength={255} ref={inputRef} onBlur={save} />
        </Form.Item>
      );
    } else if (editing && title === 'Preferred Pack') {
      childNode = (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onBlur={save} onChange={onChangeNumber} />
        </Form.Item>
      );
    } else if (editing) {
      let rules = [
        {
          required: false,
          message: `${title} is required.`
        }
      ];
      childNode = (
        <Form.Item className="editable-cell-value-wrap-form" name={dataIndex} rules={rules}>
          {dataIndex === 'revisedPgmLot' ? (
            <Input
              ref={inputRef}
              onBlur={save}
              defaultValue="R-"
              onChange={onChangeLotNum}
              maxLength={revisedPgmLotMaxLength}
            />
          ) : (
            <Input ref={inputRef} onBlur={save} onChange={onChangeLotNum} />
          )}
        </Form.Item>
      );
    } else {
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{
            backgroundColor: getBackgroundHighlighted(record, dataIndex)
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};
const getBackgroundHighlighted = (record, dataIndex) => {
  const publishedKey = 'published' + (dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1));
  const booleanWithYN = ['doNotSource', 'validated', 'verified', 'underContract'];
  if (booleanWithYN.includes(dataIndex)) {
    return record[dataIndex] && record[dataIndex] !== assignBooleanValues(record, publishedKey) ? '#D7EEF7' : '';
  }
  return record[dataIndex] && record[dataIndex] !== record[publishedKey] ? '#D7EEF7' : '';
};
const assignBooleanValues = (item, key) => {
  if (key in item) {
    if (item[key] === null) {
      return null;
    }
    return item[key] ? 'Y' : 'N';
  }
  return null;
};

let editedRows = [];

export default function LottingTable({
  children,
  showAttributes,
  osdAttributes,
  revisedOsdAttributes,
  showOsdAttributes,
  selectedOsdColumns,
  selectedOptionalColumns,
  tableData,
  pageSize,
  totalPages,
  tableChangeHandler,
  lotStatus,
  toggleClearFilters,
  getNumFilterType,
  reFetchData
}) {
  const { confirm } = Modal;
  //selectors
  const isItemLoading = useSelector(isItemsLoading);
  const osdFilters = useSelector(selectOsdFilters);
  const user = useSelector(selectUser);
  const paperLotNumberFilter = useSelector(selectedPaperLotNumberFilters);
  const revisedPaperLotNumberFilter = useSelector(selectedRevisedPaperLotNumberFilters);
  const packFilter = useSelector(selectedPackFilters);
  const sizeFilter = useSelector(selectedSizeFilters);
  const catManEventFilter = useSelector(selectedCatManEventFilters);
  const catManLotFilter = useSelector(selectedCatManLotFilters);
  const saveInputLoading = useSelector(userInputLoading);
  const saveInputError = useSelector(userInputError);
  //states
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [_tableData, _setTableData] = useState([]);
  const [_permanentTableData, _setPermanentTableData] = useState([]);
  const [rowsOnEdit, setRowsOnEdit] = useState([]);
  const [osdFilterList, setOsdFilterList] = useState([]);
  const [filtersObject, setFiltersObject] = useState({});
  const [pagination, setPagination] = useState({});
  const [sorterObject, setSorterObject] = useState({});
  const [extra, setExtra] = useState({});
  const [paperLotNumberFilterList, setPaperLotNumberFilterList] = useState([]);
  const [revisedPaperLotNumberFilterList, setRevisedPaperLotNumberFilterList] = useState([]);
  const [packFilterList, setPackFilterList] = useState([]);
  const [sizeFilterList, setSizeFilterList] = useState([]);
  const [catManEventFilterList, setCatManEventFilterList] = useState([]);
  const [catManLotFilterList, setCatManLotFilterList] = useState([]);
  const [selectedNumberFilterOption, setSelectedNumberFilterOption] = useState('');
  const [numFilterValuesMap, setNumbFilterValueMap] = useState(null);

  const numericSearchInputRef = useRef();

  const dispatch = useDispatch();
  loggedInUser = user;

  useEffect(() => {
    tableChangeHandler(pagination, filtersObject, sorterObject, extra);
  }, [pagination, filtersObject, sorterObject, extra]);

  useEffect(() => {
    if (tableData) {
      editedRows = [];
      const modifiedTableData = tableData.map(item => {
        // prepare paperLot String
        const packageSizeFlag = item.packageSizeFlag ? item.packageSizeFlag : '';
        const brandDominatorFlag = item.brandDominatorFlag ? item.brandDominatorFlag : '';
        const paperLotString = item.attributeString.concat('|', brandDominatorFlag, '|', packageSizeFlag);
        // eslint-disable-next-line no-underscore-dangle
        const _item = {
          ...item,
          paperLotString,
          isOrderable: item.isOrderable === true ? 'Y' : 'N',
          doNotSource: assignBooleanValues(item, 'doNotSource') || '',
          validated: assignBooleanValues(item, 'validated') || '',
          verified: assignBooleanValues(item, 'verified') || '',
          underContract: assignBooleanValues(item, 'underContract') || '',
          pqaSpecItem: item?.pqaSpecItem || '',
          scoreTotal: `${item.scoreTotal}%`,
          scoreAttr: `${item.scoreAttr}%`,
          scoreFnb: `${item.scoreFnb}%`,
          scoreImage: `${item.scoreImage}%`
        };
        const attributeJson = JSON.parse(_item.attributeJson);
        attributeJson.forEach(attribute => {
          const columnKey = attribute.Attribute.toLowerCase()
            .trim()
            .split(/[ -_]/g)
            .map((word, index) => {
              if (index > 0) {
                if (word[0]) {
                  return word.replace(word[0], word[0].toString().toUpperCase());
                }
              }
              return word;
            })
            .join('');
          _item[columnKey] = attribute.Decided_Value;
        });

        return _item;
      });
      _setTableData(modifiedTableData);
      _setPermanentTableData(modifiedTableData);
      setRowsOnEdit([]);
    }
  }, [tableData]);

  useEffect(() => {
    if (osdFilters?.data) {
      setOsdFilterList(osdFilters.data);
    }
  }, [osdFilters]);

  useEffect(() => {
    if (paperLotNumberFilter?.data) {
      const revised = paperLotNumberFilter?.data
        .filter(item => String(item).includes('R-'))
        .sort((a, b) => String(a).slice(2) - String(b).slice(2));
      const paperLotNumbers = paperLotNumberFilter?.data
        .filter(item => !String(item).includes('R-'))
        .sort((a, b) => a - b);
      const sortedValues = [...revised, ...paperLotNumbers];
      setPaperLotNumberFilterList(sortedValues);
    }
  }, [paperLotNumberFilter]);

  useEffect(() => {
    if (revisedPaperLotNumberFilter?.data) {
      const sorted = revisedPaperLotNumberFilter?.data.sort(sortData);
      setRevisedPaperLotNumberFilterList(sorted);
    }
  }, [revisedPaperLotNumberFilter]);

  useEffect(() => {
    if (packFilter?.data) {
      const sorted = packFilter?.data.sort(sortData);
      setPackFilterList(sorted);
    }
  }, [packFilter]);

  useEffect(() => {
    if (sizeFilter?.data) {
      const sorted = sizeFilter?.data.sort((a, b) => a.localeCompare(b));
      setSizeFilterList(sorted);
    }
  }, [sizeFilter]);

  useEffect(() => {
    if (catManEventFilter?.data) {
      const sorted = catManEventFilter?.data.sort((a, b) => a.localeCompare(b));
      setCatManEventFilterList(sorted);
    }
  }, [catManEventFilter]);

  useEffect(() => {
    if (catManLotFilter?.data) {
      const sorted = catManLotFilter?.data.sort((a, b) => a.localeCompare(b));
      setCatManLotFilterList(sorted);
    }
  }, [catManLotFilter]);

  useEffect(() => {
    setFiltersObject({});
    setSorterObject({});
    setSearchText('');
    setSelectedNumberFilterOption('');
  }, [toggleClearFilters]);

  useEffect(() => {
    getNumFilterType(numFilterValuesMap);
  }, [numFilterValuesMap]);

  useEffect(() => {
    if (!saveInputLoading) {
      if (!saveInputError) {
        setRowsOnEdit([]);
        editedRows = [];
        reFetchData();
      }
    }
  }, [saveInputLoading]);

  const sortData = (a, b) => {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      return -1;
    }
    if (b === null) {
      return 1;
    }
    return a - b;
  };

  const CustomTitle = ({ title }) => {
    return (
      <div className="customTitle-base">
        <div className="customTitle-title">{title}</div>
        <div className="customTitle-menu">
          <Popover
            content={
              <div className="lotting-header-menu-base">
                <div className="header">Move</div>

                <Switch>SW</Switch>
                <div className="move-base">
                  <Button type="text" icon={<NextIcon flip size={20} />} size="middle" />
                  <Button type="text" icon={<NextIcon size={20} />} size="middle" />
                </div>
                <div className="remove-base">
                  <Button type="primary" danger>
                    REMOVE
                  </Button>
                </div>
              </div>
            }
            trigger="click"
            placement="bottom"
          >
            <Button type="text" icon={<KebabIcon size={18} />} size="small" />
          </Popover>
        </div>
      </div>
    );
  };

  const populateCheckboxFilters = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filterList, dataIndex }) => {
    return (
      <div style={{ padding: 8 }}>
        <Checkbox.Group
          value={selectedKeys}
          onChange={value => {
            setSelectedKeys(value);
          }}
        >
          {filterList.map(item => (
            <div key={item.value} style={{ display: 'block', marginBottom: '0.8rem' }}>
              <Checkbox value={item.value}>{item.label}</Checkbox>
            </div>
          ))}
        </Checkbox.Group>
        <Divider style={{ margin: '.5em 0' }} />
        <Space>
          <Button
            type="link"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    );
  };

  const paperlotNumberFilterDropDown = compact => {
    return populateFilterValuesFromState({ ...compact, filterList: paperLotNumberFilterList });
  };
  const revisedPaperlotNumberFilterDropDown = compact => {
    const filtered = revisedPaperLotNumberFilterList.filter(number => number !== null);
    return populateFilterValuesFromState({ ...compact, filterList: ['', ...filtered] });
  };

  const packFilterDropDown = compact => {
    return populateFilterValuesFromState({ ...compact, filterList: packFilterList });
  };
  const sizeFilterDropDown = compact => {
    return populateFilterValuesFromState({ ...compact, filterList: sizeFilterList });
  };
  const catManEventFilterDropDown = compact => {
    return populateFilterValuesFromState({ ...compact, filterList: catManEventFilterList });
  };
  const catManLotFilterDropDown = compact => {
    return populateFilterValuesFromState({ ...compact, filterList: catManLotFilterList });
  };
  const childItemFlagFilterCheckbox = compact => {
    const filterList = [
      { label: ' ', value: ' ' },
      { label: 'Y', value: 'Y' },
      { label: 'N', value: 'N' }
    ];
    return populateCheckboxFilters({ ...compact, filterList, dataIndex: 'childItemFlag' });
  };
  const populateFilterValuesFromState = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filterList }) => {
    return (
      <div style={{ padding: 8 }}>
        <Select
          showSearch={true}
          mode="multiple"
          style={{ width: '200px' }}
          placeholder="Please select"
          value={[...selectedKeys]}
          onChange={value => {
            setSelectedKeys([...value]);
          }}
          options={filterList?.map(itm => ({ label: itm, value: itm }))}
          placement="topRight"
        />
        <Divider style={{ marginBottom: '.5em' }} />
        <Space>
          <Button
            type="link"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    );
  };
  let searchInput = null;
  let resetButton = null;
  const defaultLockedColumns = [
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      fixed: 'left',
      width: '8rem',
      search: true,
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Material Desc.',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      fixed: 'left',
      width: '18rem',
      search: true,
      sorter: true,
      wordCount: 32,
      sortDirections: ['ascend', 'descend'],
      className: 'material-dec'
    },
    {
      title: 'Material Unabbreviated Desc.',
      dataIndex: 'materialUnabbreviatedDescription',
      key: 'materialUnabbreviatedDescription',
      width: '18rem',
      search: true,
      sorter: true,
      wordCount: 32,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Pack',
      dataIndex: 'pack',
      key: 'pack',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'right',
      filterDropdown: packFilterDropDown
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'right',
      filterDropdown: sizeFilterDropDown
    },
    {
      title: 'Storage Code',
      dataIndex: 'storageCode',
      key: 'storageCode',
      width: '10rem',
      search: true,
      sorter: true,
      align: 'center',
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName',
      width: '15rem',
      search: true,
      sorter: true,
      wordCount: 22,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Brand Type',
      dataIndex: 'brandType',
      key: 'brandType',
      width: '7rem',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: [
        { text: '', value: '' },
        { text: 'CAB or CHB', value: 'CAB or CHB' },
        { text: 'Corp Prop', value: 'Corp Prop' },
        { text: 'Corp Prop GTIN', value: 'Corp Prop GTIN' },
        { text: 'National', value: 'National' },
        { text: 'Packer', value: 'Packer' },
        { text: 'Sample, Promo, NonProd and Misc', value: 'Sample, Promo, NonProd and Misc' },
        { text: 'Sysco', value: 'Sysco' },
        { text: 'USDA', value: 'USDA' }
      ],
      filterSearch: true,
      render: value => value
    },
    {
      title: 'True Vendor Name',
      dataIndex: 'trueVendorName',
      search: true,
      key: 'trueVendorName',
      wordCount: 15,
      width: '10rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Sale per case',
      key: 'salesPerCase',
      dataIndex: 'salesPerCase',
      width: '8rem',
      hidden: true,
      search: false,
      className: 'lotting-fixed-column', //  need this className for dash border width calcualtion
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && '$' + value.toFixed(2),
      align: 'right'
    },
    {
      title: 'Case Equivalents',
      key: 'caseEquivalents',
      dataIndex: 'caseEquivalents',
      width: '8rem',
      hidden: true,
      search: false,
      className: 'lotting-fixed-column', //  need this className for dash border width calcualtion
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && numberWithCommas(value),
      align: 'right'
    },
    {
      title: 'Sales Per Pound',
      dataIndex: 'salesPerPound',
      key: 'salesPerPound',
      width: '8rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && '$' + value.toFixed(2),
      align: 'right'
    },
    {
      title: 'Pounds',
      dataIndex: 'pounds',
      key: 'pounds',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && numberWithCommas(Math.round(value)),
      align: 'right'
    },
    {
      title: 'Net Sales Ext $',
      dataIndex: 'netSalesExt',
      key: 'netSalesExt',
      width: '8rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && '$' + numberWithCommas(Math.round(value)),
      align: 'right'
    },
    {
      title: 'Net Cost Per Case',
      dataIndex: 'netCostPerCase',
      key: 'netCostPerCase',
      width: '8rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && '$' + numberWithCommas(value.toFixed(2)),
      align: 'right'
    },
    {
      title: 'Net Cost Per Pound',
      dataIndex: 'netCostPerPound',
      key: 'netCostPerPound',
      width: '8rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && '$' + numberWithCommas(value.toFixed(2)),
      align: 'right'
    },
    {
      title: 'Gross Profit Margin',
      dataIndex: 'grossProfitMargin',
      key: 'grossProfitMargin',
      width: '9rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && value.toFixed(2) + '%',
      align: 'right'
    },
    {
      title: 'Attribute String',
      dataIndex: 'attributeString',
      key: 'attributeString',
      width: '20rem',
      search: false,
      ellipsis: {
        showTitle: false
      },
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value.length > 30 ? value.substr(0, 30).concat('...') : value}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Brand Dominator Flag',
      dataIndex: 'brandDominatorFlag',
      key: 'brandDominatorFlag',
      width: '11.5rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true,
      filters: brandDominatorFlagOptions.map(option => ({ text: option, value: option }))
    },
    {
      title: 'Pack Size Flag',
      dataIndex: 'packageSizeFlag',
      key: 'packageSizeFlag',
      width: '9rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true,
      filters: packSizeFlagOptions.map(option => ({ text: option, value: option }))
    },
    {
      title: 'PaperLot String',
      dataIndex: 'paperLotString',
      key: 'paperLotString',
      width: '20rem',
      search: false,
      ellipsis: {
        showTitle: false
      },
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value.length > 30 ? value.substr(0, 30).concat('...') : value}
        </Tooltip>
      )
    },
    {
      title: 'Paper Lot #',
      dataIndex: 'paperLotNumber',
      key: 'paperLotNumber',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'right',
      filterDropdown: paperlotNumberFilterDropDown
    },
    {
      title: 'Sum By Paper Lot #',
      dataIndex: 'sumByPaperLot',
      key: 'sumByPaperLot',
      width: '10rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => value && '$' + numberWithCommas(Math.round(value)),
      align: 'right'
    },
    {
      title: 'KVI Lot',
      dataIndex: 'kviLot',
      key: 'kviLot',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => {
        if (value === 0) {
          return '0.00%';
        } else {
          return value.toFixed(2) + '%';
        }
      },
      align: 'right'
    },
    {
      title: 'KVI Lot Item',
      dataIndex: 'kviLotItem',
      key: 'kviLotItem',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => {
        if (value === 0 || typeof value === 'undefined') {
          return '0.00%';
        }
        return `${value?.toFixed(2)}%`;
      },
      align: 'right'
    },
    {
      title: 'KVI Item',
      dataIndex: 'kviItem',
      key: 'kviItem',
      width: '6rem',
      numericSearch: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: value => {
        if (value === 0) {
          return '0.00%';
        } else {
          return value.toFixed(2) + '%';
        }
      },
      align: 'right'
    },
    {
      title: 'KVI Lot Flag',
      dataIndex: 'kviLotFlag',
      key: 'kviLotFlag',
      width: '8rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: [
        { text: 'Y', value: true },
        { text: 'N', value: false }
      ],
      render: value => (value === true ? 'Y' : 'N')
    },
    {
      title: 'KVI Item Flag',
      dataIndex: 'kviItemFlag',
      key: 'kviItemFlag',
      width: '9rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: [
        { text: 'Y', value: true },
        { text: 'N', value: false }
      ],
      render: value => (value === true ? 'Y' : 'N')
    },
    {
      title: 'Verified',
      dataIndex: 'verified',
      key: 'verified',
      width: '7rem',
      search: false,
      editable: true,
      sorter: (a, b) => a.verified < b.verified,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Verified By',
      dataIndex: 'verifiedBy',
      key: 'verifiedBy',
      width: '7rem',
      search: false,
      editable: false,
      sorter: (a, b) => a.verifiedBy < b.verifiedBy,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Validated',
      dataIndex: 'validated',
      key: 'validated',
      width: '7rem',
      search: false,
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Validated By',
      dataIndex: 'validatedBy',
      key: 'validatedBy',
      width: '7rem',
      search: false,
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CatMan Event',
      dataIndex: 'catmanEvent',
      key: 'catmanEvent',
      width: '10rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filterDropdown: catManEventFilterDropDown,
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value.length > 15 ? value.substring(0, 15).concat('...') : value}
        </Tooltip>
      )
    },
    {
      title: 'CatMan Lot #',
      dataIndex: 'catmanLot',
      key: 'catmanLot',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'right',
      filterDropdown: catManLotFilterDropDown
    },
    {
      title: 'CatMan Lot Desc.',
      dataIndex: 'catmanLotDescription',
      key: 'catmanLotDescription',
      width: '15rem',
      search: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      wordCount: 22,
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value.length > 22 ? value.substring(0, 22).concat('...') : value}
        </Tooltip>
      )
    },
    {
      title: 'Revised PGM Lot #',
      dataIndex: 'revisedPgmLot',
      key: 'revisedPgmLot',
      width: '9rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'right',
      editable: true,
      filterDropdown: revisedPaperlotNumberFilterDropDown
    },
    {
      title: 'Digitally Ready',
      dataIndex: 'digitallyReady',
      key: 'digitallyReady',
      width: '7.5rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: [
        { text: 'Y', value: true },
        { text: 'N', value: false }
      ],
      render: value => (value ? 'Y' : 'N')
    },
    {
      title: 'Source',
      className: 'green',
      dataIndex: 'doNotSource',
      key: 'doNotSource',
      width: '8rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true,
      filters: [
        { text: '', value: '' },
        { text: 'Y', value: 'Y' },
        { text: 'N', value: 'N' }
      ]
    },
    {
      title: 'PGM Lot Key',
      className: 'green',
      dataIndex: 'pgmLotKey',
      key: 'pgmLotKey',
      width: '10rem',
      search: false
    },
    {
      title: 'Future Brand Strategy',
      dataIndex: 'futureBrandStrategy',
      className: 'green',
      key: 'futureBrandStrategy',
      width: '11rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true,
      filters: futureBrandStrategyOptions.map(option => ({ text: option, value: option }))
    },
    {
      title: 'Brand Name Desired',
      dataIndex: 'brandNameDesired',
      key: 'brandNameDesired',
      width: '11rem',
      className: 'green',
      search: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true
    },
    {
      title: 'PGM Lot Description',
      dataIndex: 'pgmLotDescription',
      key: 'pgmLotDescription',
      width: '18rem',
      search: true,
      className: 'green',
      editable: true
      // render: (value, data) => {
      //   const _value = getRevisedLotNameDescription(data);
      //   return (
      //     <Tooltip placement="topLeft" title={_value}>
      //       {_value.length > 30 ? _value.substr(0, 30).concat('...') : _value}
      //     </Tooltip>
      //   );
      // }
    },
    {
      title: 'Preferred Total Case Count',
      dataIndex: 'preferredTotalCaseCount',
      key: 'preferredTotalCaseCount',
      className: 'green',
      width: '11rem',
      numericSearch: true,
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true
    },
    {
      title: 'Preferred Pack',
      className: 'green',
      dataIndex: 'preferredPack',
      key: 'preferredPack',
      width: '9rem',
      numericSearch: true,
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true
    },
    {
      title: 'Preferred Size',
      className: 'green',
      dataIndex: 'preferredSize',
      key: 'preferredSize',
      width: '8rem',
      search: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true
    },
    {
      title: 'Under Contract',
      className: 'green',
      dataIndex: 'underContract',
      key: 'underContract',
      width: '7rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true
    },
    {
      title: 'Contract End Date',
      className: 'green',
      dataIndex: 'contractEndDate',
      key: 'contractEndDate',
      width: '13rem',
      search: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (
        <DatePicker
          disabled={getUserPermission(user) === 'VIEW' || lotStatus?.data?.status !== 'REVIEW' || !lotStatus.data}
          title={value}
          showYearDropdown
          defaultValue={
            record.contractEndDate
              ? moment(new Date(record.contractEndDate).toISOString().split('T')[0], 'YYYY/MM/DD')
              : ''
          }
          onChange={(date, dateString) => {
            onChange(date, { contractEndDate: dateString }, record);
          }}
        />
      )
    },
    {
      title: 'Core Indicator',
      className: 'green',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      dataIndex: 'coreIndicator',
      key: 'coreIndicator',
      width: '9rem',
      search: false,
      filters: [
        { text: 'Y', value: 'Y' },
        { text: 'N', value: 'N' }
      ]
    },
    {
      title: 'PQA Spec Item',
      className: 'green',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true,
      dataIndex: 'pqaSpecItem',
      key: 'pqaSpecItem',
      width: '9rem',
      search: false,
      filters: [
        { text: '', value: '' },
        { text: 'Y', value: 'Y' },
        { text: 'N', value: 'N' }
      ]
    },
    {
      title: 'Notes To Sourcing',
      className: 'green',
      dataIndex: 'notesToSourcing',
      key: 'notesToSourcing',
      width: '15rem',
      search: true,
      ellipsis: {
        showTitle: false
      },
      render: notesToSourcing => (
        <Tooltip placement="topLeft" title={notesToSourcing}>
          {notesToSourcing?.length > 25 ? notesToSourcing.substr(0, 25).concat('...') : notesToSourcing}
        </Tooltip>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      editable: true
    },
    {
      title: (
        <div className="custom-title-base">
          <div className="custom-title-title">Action</div>
          <div className="custom-title-menu">
            {editedRows.length > 0 && (
              <Button
                disabled={saveInputLoading}
                onClick={() => saveAllUserInput()}
                type="primary"
                className="btn"
                size="small"
              >
                Save All
              </Button>
            )}
          </div>
        </div>
      ),
      key: 'operation',
      width: '10rem',
      render: (text, record, index) => {
        if (rowsOnEdit.includes(record.supc)) {
          onChangeUserInput(record);
        }

        return (
          <div className="user-input-btn">
            <Button
              type="primary"
              style={{ visibility: !rowsOnEdit.includes(record.supc) ? 'hidden' : 'visible' }}
              disabled={!rowsOnEdit.includes(record.supc)}
              onClick={() => handleCancelUserInputs(record)}
            >
              Cancel
            </Button>
          </div>
        );
      },
      fixed: rowsOnEdit.length != 0 ? 'right' : ''
    }
  ];

  const optionalColumnList = [
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      width: '7rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Manufacturer Product Code',
      dataIndex: 'manufacturerProductCode',
      key: 'manufacturerProductCode',
      width: '9rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Sysco Brand Flag',
      dataIndex: 'syscoBrandFlag',
      key: 'syscoBrandFlag',
      width: '9rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Corporate Proprietary Flag',
      dataIndex: 'corporateProprietaryFlag',
      key: 'corporateProprietaryFlag',
      width: '11rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Child Item Flag',
      dataIndex: 'childItemFlag',
      key: 'childItemFlag',
      width: '9rem',
      search: false,
      sorter: true,
      checkBoxSelect: true,
      sortDirections: ['ascend', 'descend'],
      filterDropdown: childItemFlagFilterCheckbox
    },
    {
      title: 'True Vendor Number',
      dataIndex: 'trueVendorNumber',
      search: true,
      key: 'trueVendorNumber',
      width: '10rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Logically Active Flag',
      dataIndex: 'logicallyActiveFlag',
      key: 'logicallyActiveFlag',
      width: '10rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Is Orderable',
      dataIndex: 'isOrderable',
      key: 'isOrderable',
      width: '7rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Item Status Code',
      dataIndex: 'itemStsCode',
      key: 'itemStsCode',
      width: '11rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Revised Pricing Lot Id',
      dataIndex: 'revisedPricingLotId',
      key: 'revisedPricingLotId',
      search: true,
      width: '12rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Revised Pricing Lot Key',
      dataIndex: 'revisedPricingLotKey',
      search: true,
      key: 'revisedPricingLotKey',
      width: '12rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Anchor Point SUPC',
      dataIndex: 'anchorPointSupc',
      key: 'anchorPointSupc',
      width: '9rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'LPG Driver',
      dataIndex: 'lpgDriver',
      key: 'lpgDriver',
      width: '7rem',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    }
  ];

  const digitalColumnList = [
    {
      title: 'Images score',
      key: 'scoreImage',
      dataIndex: 'scoreImage',
      width: '10rem',
      sorter: true,
      search: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Total score',
      key: 'scoreTotal',
      dataIndex: 'scoreTotal',
      width: '10rem',
      sorter: true,
      search: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'FnB score',
      key: 'scoreFnb',
      dataIndex: 'scoreFnb',
      width: '10rem',
      sorter: true,
      search: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Attribution score',
      key: 'scoreAttr',
      dataIndex: 'scoreAttr',
      width: '10rem',
      sorter: true,
      search: true,
      sortDirections: ['ascend', 'descend']
    }
  ];
  const addSearchFilterToColumn = column => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          {getSearchInput(column.dataIndex, column.title, setSelectedKeys, selectedKeys, confirm)}
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, column.dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
            disabled={selectedKeys[0]?.trim() === ''}
          >
            Search
          </Button>
          <Button
            ref={node => {
              resetButton = node;
            }}
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <i className="icon fi flaticon-magnifying-glass" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        if (record[column.dataIndex]) {
          return record[column.dataIndex]
            .toString()
            .toLowerCase()
            .trim()
            .includes(value.toLowerCase().trim());
        }
        return false;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: text => {
        const word = Number.isInteger(text) ? String(text) : text;
        // eslint-disable-next-line no-nested-ternary
        return searchedColumn === column.dataIndex && searchText?.length > 0 ? (
          <Tooltip placement="topLeft" title={word}>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={getSearchWords(searchText)}
              autoEscape
              textToHighlight={
                word?.length > (column?.wordCount || 15)
                  ? word?.substr(0, column?.wordCount || 15)?.concat('...')
                  : word
              }
            />
          </Tooltip>
        ) : ['brandName', 'trueVendorName', 'materialUnabbreviatedDescription', 'catmanLotDescription'].includes(
            column.dataIndex
          ) ? (
          <Tooltip placement="topLeft" title={word}>
            {word?.length > (column?.wordCount || 15) ? word?.substr(0, column?.wordCount || 15)?.concat('...') : word}
          </Tooltip>
        ) : (
          word
        );
      }
    };
  };

  const handleNumSearch = (confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const updateColumn = (dataIndex, logicType) => {
    setNumbFilterValueMap({
      ...numFilterValuesMap,
      [dataIndex]: logicType
    });
  };
  const handleNumericInputChange = (setSelectedKeys, numberInputValue, logicType, dataIndex) => {
    setSelectedKeys(numberInputValue ? [`${numberInputValue}-${logicType}`] : []);
    updateColumn(dataIndex, logicType);
  };

  const handleDisableButton = columnValue => {
    return typeof columnValue === 'undefined';
  };

  const numericSearchFilterColumn = column => {
    const dataIndex = column?.dataIndex;
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="table-filter-wrapper">
          <Radio.Group
            value={selectedNumberFilterOption}
            onChange={e => {
              const { value } = e.target;
              setSelectedNumberFilterOption(value);
              setSelectedKeys([]);
            }}
          >
            <Radio name="greaterThan" value="greaterThan" className="radio-unit">
              <span>Greater than</span>
              {selectedNumberFilterOption === 'greaterThan' && (
                <Input
                  autoFocus={true}
                  onBlur={e =>
                    getTheLogicValue(
                      filtersObject,
                      dataIndex,
                      selectedNumberFilterOption,
                      setSelectedNumberFilterOption
                    )
                  }
                  type="number"
                  style={{
                    width: '6rem'
                  }}
                  className="radio-input"
                  onChange={e => handleNumericInputChange(setSelectedKeys, e?.target?.value, 'greaterThan', dataIndex)}
                  ref={numericSearchInputRef}
                  value={formatSelectedKey(selectedKeys)}
                />
              )}
            </Radio>
            <Radio name="lessThan" value="lessThan" className="radio-unit">
              <span>Less than</span>
              {selectedNumberFilterOption === 'lessThan' && (
                <Input
                  autoFocus={true}
                  onBlur={e =>
                    getTheLogicValue(
                      filtersObject,
                      dataIndex,
                      selectedNumberFilterOption,
                      setSelectedNumberFilterOption
                    )
                  }
                  type="number"
                  style={{
                    width: '6rem'
                  }}
                  className="radio-input"
                  onChange={e => handleNumericInputChange(setSelectedKeys, e?.target?.value, 'lessThan', dataIndex)}
                  ref={numericSearchInputRef}
                  value={formatSelectedKey(selectedKeys)}
                />
              )}
            </Radio>
            <Radio name="equal" value="equal" className="radio-unit">
              <span>Equal to</span>
              {selectedNumberFilterOption === 'equal' && (
                <Input
                  autoFocus={true}
                  onBlur={e =>
                    getTheLogicValue(
                      filtersObject,
                      dataIndex,
                      selectedNumberFilterOption,
                      setSelectedNumberFilterOption
                    )
                  }
                  type="number"
                  style={{
                    width: '6rem'
                  }}
                  className="radio-input"
                  onChange={e => handleNumericInputChange(setSelectedKeys, e?.target?.value, 'equal', dataIndex)}
                  ref={numericSearchInputRef}
                  value={formatSelectedKey(selectedKeys)}
                />
              )}
            </Radio>
          </Radio.Group>
          <Divider style={{ marginBottom: '.5em' }} />
          <Button
            type="primary"
            onClick={() => {
              handleNumSearch(confirm, column.dataIndex);
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
            disabled={handleDisableButton(formatSelectedKey(selectedKeys))}
          >
            Search
          </Button>
          <Button
            ref={node => {
              resetButton = node;
            }}
            onClick={() => {
              // eslint-disable-next-line no-use-before-define
              handleReset(clearFilters, confirm);
              setSelectedNumberFilterOption('');
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <i
          role="button"
          className="icon fi flaticon-magnifying-glass"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => numericSearchInputRef.current?.select(), 100);
        }
      }
    };
  };

  let tableColumns = [...defaultLockedColumns];
  const actionColumn = tableColumns.pop(); // removing action column at last
  if (showOsdAttributes) {
    if (selectedOsdColumns && selectedOsdColumns.length > 0) {
      const osdColumns = selectedOsdColumns.map(keys => {
        return {
          title: keys.title,
          dataIndex: keys.key,
          key: keys.key,
          describingOrDetermining: keys.describingOrDetermining,
          width: '15rem',
          search: false
        };
      });

      const osdAttributes = {
        title: (
          <div className="group-label" title="OSD Attributes">
            OSD Attributes
          </div>
        ),
        dataIndex: 'osdAttributes',
        key: 'osdAttributes',
        className: 'osd-columns',
        children: osdColumns.map(column => {
          const _column = {
            ...column,
            className: 'ant-table-cell' + (column.describingOrDetermining == 'Determining' ? '-det' : '-des'),
            filterSearch: true,
            filters:
              osdFilterList &&
              osdFilterList[column.title]
                ?.map(item => ({ text: item, value: item }))
                ?.sort((a, b) => a?.value?.localeCompare(b?.value)),
            filteredValue: filtersObject[column.dataIndex] || null
          };
          if (column.search) {
            return {
              ..._column,
              ...addSearchFilterToColumn(column)
            };
          }
          return _column;
        }),
        width: '10rem',
        search: false
      };
      tableColumns.push(osdAttributes);
    }
  } else {
    const osdAttributesIndex = tableColumns.findIndex(column => column.key === 'osdAttributes');
    if (osdAttributesIndex > -1) {
      tableColumns.splice(osdAttributesIndex, 1);
    }
  }

  if (showAttributes) {
    if (selectedOptionalColumns && selectedOptionalColumns.length > 0) {
      let pickedDigitalColumns = [];
      let pickedOptionalColumns = [];

      const digitalColKeys = _digitalColumns.map(col => col.key);
      selectedOptionalColumns.forEach(col => {
        if (digitalColKeys.includes(col.key)) {
          pickedDigitalColumns.push(col);
        } else {
          pickedOptionalColumns.push(col);
        }
      });

      pickedOptionalColumns = pickedOptionalColumns.map(col => {
        const column = optionalColumnList.find(item => item.key === col.key);
        if (column) {
          if (column.search) {
            return {
              ...column,
              dataIndex: column.key,
              filteredValue: filtersObject[column?.key] || null,
              ...addSearchFilterToColumn(column)
            };
          }
          if (column.checkBoxSelect) {
            return {
              ...column,
              dataIndex: column.key,
              filteredValue: filtersObject[column?.key] || null
            };
          }
          return column;
        }
        return column;
      });
      const optionalAttributes = {
        title: (
          <div className="group-label" title="Optional Attributes">
            Optional Attributes
          </div>
        ),
        dataIndex: 'optionalAttributes',
        key: 'optionalAttributes',
        search: false,
        children: pickedOptionalColumns,
        width: '8rem',
        className: 'optional-columns'
      };

      if (pickedOptionalColumns.length > 0) {
        tableColumns.push(optionalAttributes);
      }

      pickedDigitalColumns = pickedDigitalColumns.map(col => {
        const column = digitalColumnList.find(item => item.key === col.key);
        if (column) {
          if (column.search) {
            return {
              ...column,
              filteredValue: filtersObject[column?.key] || null
            };
          }
          if (column.checkBoxSelect) {
            return {
              ...column,
              filteredValue: filtersObject[column?.key] || null
            };
          }
          return column;
        }
        return column;
      });
      const index = tableColumns.findIndex(item => item.dataIndex === 'digitallyReady');
      tableColumns.splice(index + 1, 0, ...pickedDigitalColumns);
    }
  }

  tableColumns.push(actionColumn); // adding action column at last
  const getSearchInput = (dataIndex, title, setSelectedKeys, selectedKeys, confirm) => {
    if (dataIndex === 'pack') {
      return (
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={e => filterNumberInput(e, setSelectedKeys)}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      );
    } else {
      return (
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      );
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys && selectedKeys[0]?.trim());
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const filterNumberInput = (e, setSelectedKeys) => {
    let value = e.target.value ? [e.target.value.replace(/(\D)|(^0+)/gm, '')] : []; ///(\D)|(^0+)/gm
    if (value && value[0] && value[0].length > 9) {
      return false;
    }
    setSelectedKeys(value);
    return true;
  };
  let modifiedTableColumns = tableColumns.map(column => {
    let columnFilterState = column;
    if (!['osdAttributes', 'optionalAttributes'].includes(column)) {
      columnFilterState = {
        ...columnFilterState,
        filteredValue: filtersObject[columnFilterState.dataIndex] || null
      };
      if (column.sorter) {
        columnFilterState = {
          ...columnFilterState,
          sortOrder: sorterObject.columnKey === column.key ? sorterObject.order : null
        };
      }
    }
    if (columnFilterState.key === 'optionalAttributes') {
      const optional = [...columnFilterState?.children].map(column => {
        return { ...column, sortOrder: sorterObject.columnKey === column.key ? sorterObject.order : null };
      });
      columnFilterState.children = optional;
    }
    if (columnFilterState.search) {
      return { ...columnFilterState, ...addSearchFilterToColumn(columnFilterState) };
    }
    if (columnFilterState.numericSearch) {
      return { ...columnFilterState, ...numericSearchFilterColumn(columnFilterState) };
    }
    return columnFilterState;
  });
  const onChange = (date, dateString, row) => {
    let updatedRow = { ...row, ...dateString };
    handleUserInputs(updatedRow);
  };

  const handleUserInputs = row => {
    const _rowsOnEdit = [...rowsOnEdit];
    _rowsOnEdit.push(row.supc);
    setRowsOnEdit(_rowsOnEdit);
    const newData = [..._tableData];
    const index = newData.findIndex(item => row.key === item.key);
    let item = newData[index];
    newData.splice(index, 1, { ...item, ...row, updatedTime: moment().format('YYYY-MM-DD HH:mm:ss') });
    _setTableData(newData);
  };

  const makeUserInputPayload = item => {
    let supc = item.supc;
    let originalRowValues = tableData.find(x => x.supc === supc);
    let verifiedBy = item.verifiedBy;
    if (originalRowValues?.verified !== item?.verified) {
      verifiedBy = item.verified === null || item.verified === '' ? null : user.email;
    }
    let validatedBy = item.validatedBy;
    if (originalRowValues?.validated !== item?.validated) {
      validatedBy = item.validated === null || item.validated === '' ? null : user.email;
    }
    let userInputCreateDto = {
      brandNameDesired: item.brandNameDesired,
      contractEndDate: item.contractEndDate ? moment(item.contractEndDate).format('YYYY-MM-DD HH:mm:ss') : undefined,
      contractEndSale: item.contractEndSale,
      doNotSource: item.doNotSource === null || item.doNotSource === '' ? null : item.doNotSource === 'Y',
      pqaSpecItem: item.pqaSpecItem === null || item.pqaSpecItem === '' ? null : item.pqaSpecItem === 'Y' ? 'Y' : 'N',
      futureBrandStrategy: item.futureBrandStrategy,
      hierarchicalId: item.hierarchicalId,
      notesToSourcing: item.notesToSourcing,
      pgmRevisedLotNameDescription: item.pgmRevisedLotNameDescription,
      preferredPack: item.preferredPack,
      preferredSize: item.preferredSize,
      preferredTotalCaseCount: item.preferredTotalCaseCount,
      reviewBrandDominatorFlag: item.brandDominatorFlag,
      reviewBrandNameDesired: item.brandNameDesired,
      reviewNotesToSourcing: item.notesToSourcing,
      reviewPackageSizeFlag: item.packageSizeFlag,
      reviewPgmRevisedLotNameDescription: item.pgmRevisedLotNameDescription,
      reviewPreferredPack: item.preferredPack,
      reviewPreferredSize: item.preferredSize,
      reviewPreferredTotalCaseCount: item.preferredTotalCaseCount,
      reviewFutureBrandStrategy: item.futureBrandStrategy,
      reviewRevisedPgmLot: item.revisedPgmLot && item.revisedPgmLot.trim().length > 0 ? item.revisedPgmLot : null,
      reviewPqaSpecItem:
        (item.pqaSpecItem === undefined && null) || item.pqaSpecItem === null || item.pqaSpecItem === ''
          ? null
          : item.pqaSpecItem === 'Y'
          ? 'Y'
          : 'N',
      status: 'R', //for initial review.
      underContract: item.underContract === null || item.underContract === '' ? null : item.underContract === 'Y',
      updatedBy: user.contactId,
      updatedTime: item.updatedTime,
      validated: item.validated === null || item.validated === '' ? null : item.validated === 'Y',
      validatedBy: validatedBy,
      validatedUpdatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      verified: item.verified === null || item.verified === '' ? null : item.verified === 'Y',
      verifiedBy: verifiedBy,
      verifiedUpdatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      pgmLotDescription: item.pgmLotDescription,
      pgmLotNumber: item.paperLotNumber
    };
    return { supc: supc, ...userInputCreateDto };
  };
  const saveUserInput = item => {
    const userInput = makeUserInputPayload(item);
    dispatch(saveUserInputs({ data: [userInput] }));
    unloadEditRows(item.supc);
    const arr = editedRows.filter(row => row.supc != item.supc);
    editedRows = arr;
  };

  const saveAllUserInput = () => {
    confirm({
      title: 'Are you sure you want to save all changes?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(saveUserInputs({ data: editedRows }));
      },
      okText: 'Save',
      cancelText: 'Cancel',
      className: 'confirm-duplication-modal'
    });
  };

  const onChangeUserInput = item => {
    let arr = [];
    editedRows.forEach(row => {
      if (row.supc != item.supc) {
        arr.push(row);
      }
    });
    arr = arr.sort((arr1, arr2) => (moment(arr1?.updatedTime).isAfter(moment(arr2?.updatedTime)) ? 1 : -1));
    const row = makeUserInputPayload({ ...item });
    arr.push(row);
    editedRows = arr;
    console.log(arr);
  };

  modifiedTableColumns = modifiedTableColumns.map(col => {
    if (getUserPermission(user) === 'EDIT' && col.editable && lotStatus?.data?.status === 'REVIEW') {
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleUserInputs: handleUserInputs
        })
      };
    }
    return col;
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const handleCancelUserInputs = row => {
    const newData = [..._tableData];
    const permanentData = [..._permanentTableData];
    const pIndex = permanentData.findIndex(item => row.key === item.key);
    const index = newData.findIndex(item => row.key === item.key);
    let pItem = permanentData[pIndex];
    let item = newData[index];
    newData.splice(index, 1, pItem);
    _setTableData(newData);
    unloadEditRows(row.supc);
    editedRows = editedRows.filter(item => item.supc != row.supc);
  };

  const unloadEditRows = supc => {
    const _rowsOnEdit = [...rowsOnEdit];
    let unSavedSupcs = _rowsOnEdit.filter((value, index, arr) => {
      return value != supc;
    });
    setRowsOnEdit(unSavedSupcs);
  };

  const getRevisedLotNameDescription = data => {
    const attributeJsonArray = JSON.parse(data.attributeJson);
    let attributeStringArray = [];
    revisedOsdAttributes &&
      revisedOsdAttributes.forEach(attribute => {
        if (attribute.describingOrDetermining === 'Determining') {
          const _attribute = attributeJsonArray.find(item => item.Attribute === attribute.keyName);
          if (_attribute) {
            attributeStringArray.push(_attribute.Decided_Value || '');
          }
        }
      });
    data.pgmRevisedLotNameDescription = attributeStringArray.join('|');
    return attributeStringArray.join('|');
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    setFiltersObject(filters);
    setPagination(pagination);
    setSorterObject(sorter);
    setExtra(extra);
  };

  return (
    <>
      {_tableData?.length > 0 ? (
        <Table
          components={components}
          loading={isItemLoading || saveInputLoading}
          columns={modifiedTableColumns}
          dataSource={_tableData}
          scroll={{ x: 'max-content', y: 'calc(100vh - 27.9rem)' }}
          pagination={false}
          className={`lotting-table ${isItemLoading && 'item-loading'}`}
          onChange={handleChange}
          footer={() => <>{children}</>}
        />
      ) : (
        <Table
          components={components}
          loading={isItemLoading || saveInputLoading}
          columns={modifiedTableColumns}
          dataSource={_tableData}
          scroll={{ x: 'max-content' }}
          pagination={false}
          className="lotting-table"
          onChange={handleChange}
          footer={() => <>{children}</>}
        />
      )}
    </>
  );
}
