import React, { useState, useEffect } from 'react';
import { Input, Divider, Button } from 'antd';
import SearchIcon from './icons/Search';
import CloseIcon from './icons/Close';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { prepareTableColumnValues } from 'managers/components/OptionalColumnsEditorLogic';

export const _digitalColumns = [
  { title: 'Total score', key: 'scoreTotal' },
  { title: 'Attribution score', key: 'scoreAttr' },
  { title: 'FnB score', key: 'scoreFnb' },
  { title: 'Images score', key: 'scoreImage' }
];

export default function OptionalColumnsEditor({ handleOnselectChange, hierarchyId, otherAttributeList }) {
  // eslint-disable-next-line no-underscore-dangle
  const _optionalColumns = [
    { title: 'GTIN', key: 'gtin' },
    { title: 'Manufacturer Product Code', key: 'manufacturerProductCode' },
    { title: 'Sysco Brand Flag', key: 'syscoBrandFlag' },
    { title: 'Corporate Proprietary Flag', key: 'corporateProprietaryFlag' },
    { title: 'Child Item Flag', key: 'childItemFlag' },
    { title: 'True Vendor Number', key: 'trueVendorNumber' },
    { title: 'Logically Active Flag', key: 'logicallyActiveFlag' },
    { title: 'Is Orderable', key: 'isOrderable' },
    { title: 'Item Status Code', key: 'itemStsCode' },
    { title: 'Revised Pricing Lot Id', key: 'revisedPricingLotId' },
    { title: 'Revised Pricing Lot Key', key: 'revisedPricingLotKey' },
    { title: 'Anchor Point SUPC', key: 'anchorPointSupc' },
    { title: 'LPG Driver', key: 'lpgDriver' }
  ];
  const [selectedAttribute, setSelectedAttributes] = useState([]);
  const [optionalColumns, setOptionalColumns] = useState(_optionalColumns);
  const [performAll, setPerformAll] = useState('Add All');
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    const selectedColumnKeys = selectedAttribute.map(item => prepareTableColumnValues(item));
    handleOnselectChange(selectedColumnKeys);
  }, [selectedAttribute]);

  useEffect(() => {
    if (optionalColumns && optionalColumns.length === 0) {
      setPerformAll('Remove All');
    }
    if (selectedAttribute && selectedAttribute.length === 0) {
      setPerformAll('Add All');
    }
  }, [selectedAttribute, optionalColumns]);

  useEffect(() => {
    setSearchText('');
    let filtered = [];
    _optionalColumns.forEach(option => {
      const index = selectedAttribute.findIndex(attribute => option.key === attribute.key);
      if (index === -1) {
        filtered.push(option);
      }
    });
    setOptionalColumns(filtered);
  }, [hierarchyId]);

  useEffect(() => {
    if (searchText != null) {
      const remaining = [..._digitalColumns, ..._optionalColumns].filter(item => {
        const index = selectedAttribute.findIndex(_item => _item.title === item.title);
        if (index === -1) {
          return item;
        }
      });

      if (remaining && remaining.length > 0) {
        const filtered = remaining.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase()));
        if (searchText !== '') {
          setOptionalColumns([...filtered]);
        } else {
          setOptionalColumns([...remaining]);
        }
      }
    }
  }, [searchText]);

  useEffect(() => {
    if (selectedAttribute.length == 0 && otherAttributeList.length > 0) {
      setSelectedAttributes(otherAttributeList);
    }
  }, [otherAttributeList]);

  const handleAttributeAdd = attribute => {
    const index = selectedAttribute.findIndex(_attribute => attribute.key === _attribute.key);
    if (index > -1) {
      const modified = [...selectedAttribute];
      modified[index] = attribute;
      setSelectedAttributes(modified);
    } else {
      const modified = [...selectedAttribute, attribute];
      setSelectedAttributes(modified);
    }
    removeFromOptional(attribute);
  };
  const handleAttributeRemove = attribute => {
    const modified = [...selectedAttribute];
    const index = modified.findIndex(_attribute => attribute.key === _attribute.key);
    if (index !== -1) {
      modified.splice(index, 1);
      setSelectedAttributes(modified);
    }
    addBackToOptional(attribute);
  };

  const addBackToOptional = attribute => {
    const _optional = [...optionalColumns, attribute];
    setOptionalColumns(_optional);
  };

  const removeFromOptional = attribute => {
    const _optional = [...optionalColumns];
    const index = _optional.findIndex(_attribute => attribute.key === _attribute.key);
    if (index !== -1) {
      _optional.splice(index, 1);
      setOptionalColumns(_optional);
    }
  };

  const handleSearch = searchKey => {
    setSearchText(searchKey);
  };
  const handleAddOrRemoveAll = () => {
    if (performAll === 'Add All') {
      setPerformAll('Remove All');
      setSelectedAttributes([..._digitalColumns, ..._optionalColumns]);
      setOptionalColumns([]);
    } else {
      setPerformAll('Add All');
      setOptionalColumns([..._digitalColumns, ..._optionalColumns]);
      setSelectedAttributes([]);
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const reOrdered = reorder([...selectedAttribute], result.source.index, result.destination.index);
    setSelectedAttributes(reOrdered);
  };
  return (
    <div className="column-editor-base">
      <div className="column-editor-top-search-wrapper">
        <Input
          className="optional-popup-search"
          size="large"
          placeholder="Search attributes"
          prefix={<SearchIcon size={20} color="rgba(0,0,0,0.4)" />}
          onChange={e => handleSearch(e.target.value)}
          value={searchText}
        />
        <div className="add-all-btn">
          {performAll === 'Remove All' ? (
            <Button type="primary" onClick={handleAddOrRemoveAll} danger>
              {performAll}
            </Button>
          ) : (
            <Button type="primary" onClick={handleAddOrRemoveAll}>
              {performAll}
            </Button>
          )}
        </div>
      </div>

      <div className="column-editor-top">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {selectedAttribute ? (
                  selectedAttribute.map((item, i) => {
                    return (
                      <Draggable draggableId={item.key} key={item.key} index={i}>
                        {(provided, snapshot) => (
                          <li
                            key={item.key}
                            draggable="true"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="column-editor-top-element">
                              <div className="column-editor-icon">
                                <svg width="15" height="8" viewBox="0 0 15 8">
                                  <g id="column-editor-move-handle" data-name="Group 130" transform="translate(0 0.26)">
                                    <rect
                                      id="Rectangle_46"
                                      data-name="Rectangle 46"
                                      width="15"
                                      height="3"
                                      rx="1"
                                      transform="translate(0 4.74)"
                                      fill="#CBCBCB"
                                    />
                                    <rect
                                      id="Rectangle_48"
                                      data-name="Rectangle 48"
                                      width="15"
                                      height="3"
                                      rx="1"
                                      transform="translate(0 -0.26)"
                                      fill="#CBCBCB"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="column-editor-text">{item.title}</div>
                              <div
                                className="column-editor-remove"
                                onClick={() => {
                                  handleAttributeRemove(item);
                                }}
                              >
                                <CloseIcon color="rgba(255,0,0,0.6)" size={20} />
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Divider dashed />

      <div className="column-editor-bottom">
        <ul>
          {optionalColumns ? (
            optionalColumns.map((item, i) => {
              return (
                <li key={item.key}>
                  <div className="column-editor-bottom-element-det">
                    <div className="column-editor-bottom-text">{item.title}</div>
                    <div className="column-editor-bottom-button">
                      <button onClick={() => handleAttributeAdd(item)}>ADD</button>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}
