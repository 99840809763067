import user from './InitialState/userInitialState';
import businessCenter from './InitialState/businessCenterInitialState';
import itemGroup from './InitialState/ItemGroupInitialState';
import attributeGroup from './InitialState/attributeGroupInitialState';
import osdAttribute from './InitialState/osdAttributeInitialState';
import item from './InitialState/itemInitialState';
import userInput from './InitialState/userInputInitialState';
import createRevisedOsdAttribute from './InitialState/createRevisedOsdAttributeInitialState';
import getLotStatus from './InitialState/getLotStatusInitialState';
import updateLotStatus from './InitialState/updateLotStatusInitialState';
import recalculate from './InitialState/recalculateInitialState';
import paperLotNumberFilter from './InitialState/paperLotNumberFilterInitialState';
import revisedPaperLotNumberFilter from './InitialState/revisedPaperLotNumberFilterInitialState';
import downloadPaperLotReport from './InitialState/reportDownloadInitialState';
import packFilter from './InitialState/packFilterInitialState';
import sizeFilter from './InitialState/sizeFilterInitialState';
import revisedOsdAttribute from './InitialState/revisedOsdAttributeInitialState';
import catManEventFilter from './InitialState/catManEventFilterInitialState';
import catManLotFilter from './InitialState/catManLotFilterInitialState';
import saveAll from './InitialState/saveAllInitialState';

const initialState = {
  user,
  businessCenter,
  itemGroup,
  attributeGroup,
  osdAttribute,
  item,
  createRevisedOsdAttribute,
  getLotStatus,
  updateLotStatus,
  userInput,
  recalculate,
  paperLotNumberFilter,
  revisedPaperLotNumberFilter,
  downloadPaperLotReport,
  packFilter,
  sizeFilter,
  revisedOsdAttribute,
  catManEventFilter,
  catManLotFilter,
  saveAll
};
export default initialState;
