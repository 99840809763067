import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';
import { DOWNLOAD_PAPER_LOT_REPORT } from 'actions/actionTypes';

function* downloadPaperLotReportAsync(payload) {
  try {
    const { hierarchyId } = payload;

    const url = `/paper-lot-report?hierarchy-id=${encodeURIComponent(hierarchyId)}`;
    const response = yield getRequest(url);
    yield put({ type: DOWNLOAD_PAPER_LOT_REPORT.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DOWNLOAD_PAPER_LOT_REPORT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PAPER LOT REPORT DOWNLOADING ERROR'
      })
    );
  }
}

export { downloadPaperLotReportAsync };
