import { all } from 'redux-saga/effects';
import notificationSaga from './notificationSaga';
import userSaga from './userSaga';
import hierarchySaga from './hierarchySaga';
import itemSaga from './itemSaga';
import userInputsSaga from './userInputsSaga';
import reportDownloadPaperLotSaga from './reportDownloadSaga';

export default function* rootSaga() {
  yield all([
    ...notificationSaga,
    ...userSaga,
    ...hierarchySaga,
    ...itemSaga,
    ...userInputsSaga,
    ...reportDownloadPaperLotSaga
  ]);
}
